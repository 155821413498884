<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Halaman : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-primary" class="mt-2 mt-md-0"
            @click="modalAddGaleri()">
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Halaman</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- table -->
    <b-table ref="selectableTable" selectable :select-mode="selectMode" :items="items" :fields="fields" responsive
      class="mb-0" @row-selected="onRowSelected">
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank">Open</a>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon">
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalEditHalaman(data.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="rows" :per-page="perPage" />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input id="email" type="email" placeholder="Email Address" v-model="email" />
      </b-form-group>
      <b-button class="mt-3" variant="outline-primary" block @click="exportData()">
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>


    <!-- modal add halaman-->
    <b-modal id="modal-add-halaman" title="Tambah Galeri" cancel-variant="outline-secondary" hide-footer>
      <b-form @submit.prevent>
        <b-form-group label="hal id" label-for="id ">
          <b-form-input id="id" v-model="id" placeholder="id" />
        </b-form-group>
        <b-form-group label="Judul" label-for="judul ">
          <b-form-input id="judul" v-model="judul" placeholder="judul" />
        </b-form-group>
        <b-form-group label="Content" label-for="content ">
          <b-form-textarea id="content" v-model="content" placeholder="Content" rows="3" max-rows="8"
            class="mb-1 mb-xl-0" />
        </b-form-group>
        <b-form-group label="tipe" label-for="tipe">
          <v-select id="tipe" v-model="tipe" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="option"
            placeholder="Tipe.." />
        </b-form-group>
        <br>
        <b-button variant="primary" type="submit" block :disabled="invalid" @click="saveDataHalaman">
          Tambah Galeri
        </b-button>
      </b-form>
    </b-modal>

    <!-- modal update halaman-->
    <b-modal id="modal-update-halaman" title="Edit Halaman" cancel-variant="outline-secondary" hide-footer>
      <b-form @submit.prevent>
        <b-form-input id="id_galeri" v-model="updateHalaman.id" placeholder="id" hidden />
        <b-form-group label="Hal id" label-for="gallery_title ">
          <b-form-input id="id_galeri" v-model="updateHalaman.hal_id" placeholder="id" />
        </b-form-group>
        <b-form-group label="Judul" label-for="gallery_title ">
          <b-form-input id="gallery_title" v-model="updateHalaman.hal_title" placeholder="judul" />
        </b-form-group>
        <b-form-group label="Content" label-for="content ">
          <b-form-textarea id="content" v-model="updateHalaman.hal_content" placeholder="Content" rows="3" max-rows="8"
            class="mb-1 mb-xl-0" />
        </b-form-group>
        <b-form-group label="tipe" label-for="tipe">
          <v-select id="tipe" v-model="updateHalaman.hal_type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="option" placeholder="Tipe.." />
        </b-form-group>
        <b-button variant="primary" type="submit" block :disabled="invalid" @click="updateDataHalaman">
          Update Galeri
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea, BFormFile,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      fields: [{ key: 'id', label: 'Id' }, 'hal_id', 'hal_title', 'hal_type', 'action'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        0: 'Register', 1: 'Verify', 2: "Can't find", 3: 'Input wrong data'
      },
      {
        0: 'light-primary', 1: 'light-success', 2: 'light-warning', 3: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],

      search: null,
      detailPendidikan: {},
      detailGaleri: {},
      updateHalaman: {},
      base64Data: '',
      option: ['Mobile', 'Desktop']
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getHalaman()
      }
      else {
        this.currentPage = 1
      }

    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name
      }
      return `${files.length} files selected`
    },

    modalAddGaleri() {
      this.$bvModal.show("modal-add-halaman")
    },

    modalEditHalaman(item) {
      this.updateHalaman = item
      this.$bvModal.show("modal-update-halaman")
    },
    async getHalaman() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,

        }
        const { data } = await API.halaman.list(form)
        this.data_table = data
        this.items = data.data
        this.rows = data.total

        this.handleMsgSuccess('Berhasil tampilkan data Halaman')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },


    async saveDataHalaman() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        let bodyHalaman = {
          hal_id: this.id,
          hal_title: this.judul,
          hal_content: this.content,
          hal_type: this.tipe
        }
        await API.halaman.addData(bodyHalaman)
        this.handleMsgSuccess('Berhasil tambah Halaman')
        this.refreshPage()

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'form yang anda isi salah')
      } finally {
        this.$bvModal.hide("modal-add-halaman")
        this.clearSelected()
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)

      }


    },

    async updateDataHalaman() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        var id = this.updateHalaman.id

        let bodyHalaman = {
          hal_id: this.updateHalaman.hal_id,
          hal_title: this.updateHalaman.hal_title,
          hal_content: this.updateHalaman.hal_content,
          hal_type: this.updateHalaman.hal_type
        }
        await API.halaman.updateData(id, bodyHalaman)
        this.refreshPage()
        this.handleMsgSuccess('Berhasil Update Halaman')

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'gagal update halaman')
      } finally {
        this.$bvModal.hide("modal-update-halaman")
        this.clearSelected()
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },

  },
  mounted() {
    this.getHalaman()
  },
  watch: {
    currentPage() {
      this.getHalaman()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
